import { useI18n } from '../../../hooks/useI18n';
import { api, API_URL } from '../../../api/api';
import { Wrapper, ConsultingService } from '../../../types';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';
import Navigation from '../../../components/Nav/navbar';

import Footer from '../../../components/Footer';
import { Card, Row } from 'react-bootstrap';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';

const ConsultationDomain = () => {
  const i18n = useI18n();
  const { category } = useParams();
  const { data: responseData, isLoading, isError } = useQuery({
      queryKey: [{ id: 'projects', locale: i18n.language, category }],
      queryFn: async ({ queryKey }) => {
        return await api.get<Wrapper<ConsultingService>>(`/consulting-services?filters[category][$eq]=${queryKey[0].category}&locale[0]=${queryKey[0].locale}&populate=thumbnails%2Cthumbnails.thumbnail%2Csubheader%2Cexperience%2Ctitle%2Ctitle.icon%2Cexperience.image`);
      },
    },
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error...</div>;
  }

  if (!responseData || !responseData.data || !responseData.data.data || !responseData.data.data.length) {
    return <div>No data</div>;

  }

  return (
    <div>
      <Navigation />
      {responseData.data.data.map((consultingService) => {
        return (
          <>
            <div className="heromri">
              <h3 style={{ textAlign: 'center' }}>{consultingService.attributes.title.title}</h3>
              <img src={`${API_URL}${consultingService.attributes.title.icon?.data?.attributes.url}`}
                   alt="" />
            </div>
            <Row flex className="row-thing ml-auto mr-auto text-start">
              {consultingService.attributes.thumbnails.map((thumbnail) => {
                return (
                  <Card className="card-thing">
                    <Card.Img variant="top" src={`${API_URL}${thumbnail.thumbnail?.data.attributes.url}`}
                              style={{ height: '8rem' }} />
                    <Card.Body className="card-body-thing">
                      <Card.Title className="card-title-thing">
                        <h2>{thumbnail.title}</h2>
                      </Card.Title>
                      {<BlocksRenderer content={thumbnail.content} />}
                    </Card.Body>
                  </Card>
                );
              })}
            </Row>
            <div className="inside-domains-page">
              <div style={{ textAlign: 'center' }}>
                <h2 style={{ fontSize: '40px', color: '#646363' }}>{consultingService.attributes.subheader}</h2>
              </div>
              {consultingService.attributes.experience.map((experience) => {
                return (
                  <div key={experience.id} className="row-thing" style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: '20px',
                    backgroundColor: '#FFF'
                  }}>
                    <div style={{ marginRight: '50px' }}>
                      {
                        experience.image?.data
                         ? <Card.Img
                            src={`${API_URL}${experience.image?.data?.attributes.url}`}
                            alt=""
                            className="card-image-size"
                            style={{ border: 'solid 1px gray' }}
                          />
                          : <div className="card-image-size" style={{ border: 'solid 1px gray', backgroundColor: 'grey' }}></div>
                      }
                    </div>
                    <div>
                      <h3 className="uk-card-title">
                        {experience.title}
                      </h3>
                      <p className="uk-body">
                        <BlocksRenderer content={experience.content}/>
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        );
      })}
      <Footer />
    </div>
  );
};

export default ConsultationDomain;
