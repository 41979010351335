import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Footer from '../../../components/Footer';
import Navigation from '../../../components/Nav/navbar';
// Images
import Uctb from '../../../assets/vectors/logo-utcb-ro.svg';
import Wwf from '../../../assets/vectors/wwf_logo_full.svg';
import Ti from '../../../assets/images/tehnoinstrument.png';
import Riba from '../../../assets/images/parteneri/riba.jpeg';
import Iahs from '../../../assets/images/parteneri/iahs.gif';
import Aric from '../../../assets/images/parteneri/aric.jpg';

export default function Parteneri() {
  const { t } = useTranslation();
  return (
    <div>
      <Navigation />
      <div className="inside-page">
        <h1 className="uk-card-title">{t('parteneri_membri')}</h1>

        <ul>
          <Row className="row">
            <Col md className="col-md-2">
              <img src={Aric} alt="aric" width="100" height="100" />
            </Col>
            <Col md>
              <li>{t('parteneri_aric')}</li>
            </Col>
          </Row>
          <Row className="row">
            <Col md className="col-md-2">
              <img src={Iahs} alt="iahs" width="100" height="100" />
            </Col>
            <Col md>
              <li>{t('parteneri_iahs')}</li>
            </Col>
          </Row>
          <Row className="row">
            <Col md className="col-md-2">
              <img src={Riba} alt="riba" width="100" height="100" />
            </Col>
            <Col md>
              <li>{t('parteneri_riba')}</li>
            </Col>
          </Row>
        </ul>

        <h1 className="uk-card-title">{t('parteneri')}</h1>
        <div style={{ marginLeft: '2rem' }}>
          <Row className="row">
            <Col md className="col-md-2">
              <img src={Uctb} alt="" />
            </Col>
            <Col>
              <p>Universitatea Tehnica de Constructii Bucuresti</p>
            </Col>
          </Row>
          <Row className="row">
            <Col md className="col-md-2">
              <img src={Wwf} alt="" width="100" height="100" />
            </Col>
            <Col>
              <p>World Wide Fund for Nature Romania</p>
            </Col>
          </Row>
          <Row className="row">
            <Col md className="col-md-2">
              <img src={Ti} alt="" />
            </Col>
            <Col>
              <p>Tehno Instrument</p>
            </Col>
          </Row>
        </div>
      </div>
      <Footer />
    </div>
  );
}
