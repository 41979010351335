import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Logo from '../../assets/vectors/logo.svg';
import Menu from '../Menu/Menu';
import Burger from '../Burger/Burger';
import LanguageDropdown from '../LanguageDropdown';
import { useI18n } from '../../hooks/useI18n';

export default function Navbar() {
  const [open, setOpen] = useState(false);
  const [t] = useTranslation();
  const { language, LANGUAGES } = useI18n();

  const handlePolicyDownload = () =>
    window.open(
      language === LANGUAGES.EN
        ? `https://jbaconsulting.ro/api/uploads/10_001_EN_Quality_Policy_f28e5731b7_4f78036d94.pdf`
        : `https://jbaconsulting.ro/api/uploads/10_001_RO_Politica_privind_calitatea_21f385cf22_7b7592719b.pdf`,
      '_blank'
    );

  return (
    <>
      <div className="topnav" id="myTopnav">
        <Link to="/">
          <img
            src={Logo}
            alt=""
            width="80px"
            height="80px"
            style={{ paddingTop: '.5rem' }}
          />
        </Link>
        <div className="Navi">
          <div className="dropdown">
            <button className="dropbtn" type="button">
              <Link to="/despre-noi">{t('nav_despre_noi')}</Link>
            </button>
            <div className="dropdown-content">
              <Link to="/despre-noi/conducere">{t('nav_conducere')}</Link>
              <Link to="/despre-noi/echipa">{t('nav_echipa')}</Link>
              <Link to="/despre-noi/contact">Contact</Link>
              <Link to="/despre-noi/termeni-si-conditii">{t('nav_ts')}</Link>
              <Link to="/despre-noi/politica-confidentialitate">
                {t('nav_pc')}
              </Link>
            </div>
          </div>
          <div className="dropdown">
            <button
              className="dropbtn"
              disabled
              type="button"
              style={{ MouseEvent: 'none' }}
            >
              {t('nav_suntem_jba')}
            </button>
            <div className="dropdown-content">
              <Link to="/suntem-jba/grupul-jba">{t('nav_grupul_jba')}</Link>
              <Link to="/suntem-jba/valorile-jba">{t('nav_valorile_jba')}</Link>
              <Link to="/suntem-jba/structura-jba">
                {t('nav_structura_jba')}
              </Link>
            </div>
          </div>
          <div className="dropdown">
            <button className="dropbtn" type="button">
              <Link to="/domenii-de-consultanta">
                {t('nav_domenii_consultanta')}
              </Link>
            </button>
            <div className="dropdown-content">
              <Link to="/domenii-de-consultanta/managementul-riscului-inundatii">
                {t('nav_managementul')}
              </Link>
              <Link to="/domenii-de-consultanta/consultanta-gestionare">
                {t('nav_consultanta')}
              </Link>
              <Link to="/domenii-de-consultanta/consultanta-legislativa">
                {t('nav_cons_leg')}
              </Link>
              <Link to="/domenii-de-consultanta/training-dedicat">
                {t('nav_training')}
              </Link>
            </div>
          </div>
          <div className="dropdown">
            <Link to="/acreditari-certificari">{t('certificari')}</Link>
          </div>
          <div className="dropdown">
            <button
              disabled
              className="dropbtn"
              type="button"
              style={{ MouseEvent: 'none' }}
            >
              {t('nav_parteneri')}
            </button>
            <div className="dropdown-content">
              <Link to="/parteneri/membri">{t('parteneri_membri')}</Link>
              <Link to="/parteneri/acorduri-de-parteneriat">
                <p style={{ textTransform: 'none' }}>
                  {t('acorduri_parteneriate')}
                </p>
              </Link>
            </div>
          </div>
          <div className="dropdown">
            <Link to="/stiri">{t('nav_stiri')}</Link>
          </div>
          <div className="dropdown">
            <Link to="/cariera">{t('nav_cariera')}</Link>
          </div>
          <div className="dropdown">
            <button
              className="dropbtn"
              type="button"
              style={{ MouseEvent: 'none' }}
              onClick={handlePolicyDownload}
            >
              {t('nav_politici')}
            </button>
            <div className="dropdown-content" style={{ top: '5rem' }}>
              <button
                className="dropbtn"
                type="button"
                style={{ marginTop: '7px', MouseEvent: 'none' }}
                onClick={handlePolicyDownload}
              >
                {t('politica_calitate')}
              </button>
            </div>
          </div>
          <LanguageDropdown />
        </div>
      </div>
      <Burger open={open} setOpen={setOpen} />
      <Menu open={open} setOpen={setOpen} />
    </>
  );
}
