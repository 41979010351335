// @ts-ignore
import { useState } from "react";
import { useParams } from "react-router";
import Markdown from "react-markdown";
import { Row, Card } from "react-bootstrap";
import { saveAs } from "file-saver";
import { useTranslation } from "react-i18next";
import ReactHtmlParser from "html-react-parser";
import Navigation from "../../components/Nav/navbar";
import Footer from "../../components/Footer";
import { useQuery } from "@tanstack/react-query";
import { api } from "../../api/api";
import { Wrapper, Article, WrapperData } from "../../types";
import DataDisplay from "../../components/DataDisplay";

const ArticleComponent = () => {
  const { id } = useParams();

  const {
    data: responseData,
    isLoading,
    isError,
  } = useQuery({
    queryKey: [{ name: "article", id }],
    queryFn: ({ queryKey }) => {
      return api.get<Wrapper<Article>>(`/articles/${queryKey[0].id}`);
    },
  });

  return (
    <DataDisplay
      responseData={responseData}
      isLoading={isLoading}
      isError={isError}
      RenderComponent={ArticleItems}
    />
  );
};

const ArticleItems = ({ data:article }: { data: WrapperData<Article> }) => {
  const [check, setCheck] = useState(0);
  const { t } = useTranslation();
  const getImageUrl = (url: string) => {
    const extractedUrl = url.replace("http://138.68.94.247:1337", "");
    return `https://jbaconsulting.ro/api${extractedUrl}`;
  };

  const pdfGenerate = (article: Article) => {
    for (let i = 0; i <= article.pdf.data.length; i++) {
      if (typeof article.pdf.data[i] === "undefined") {
        continue;
      }
      setCheck(1);
      saveAs(
        `https://jbaconsulting.ro/api${article.pdf.data[i].attributes.url}`,
        `${article.pdf.data[i].attributes.name}.pdf`
      );
    }
  };
  const checking = (article: Article) => {
    for (let i = 0; i <= article.pdf.data.length; i++) {
      if (typeof article.pdf.data[i] === "undefined") {
        continue;
      }
      setCheck(1);
    }
  };

  return (
    <>
      {/* @ts-ignore */}
      <div key={article.id}>
        <Navigation />
        <div className="inside-page">
          <h1 className="uk-card-title">
            {ReactHtmlParser(article.attributes.title)}
          </h1>
        </div>
        <div className="inside-page">
          <Row
            flex="true"
            className="row-thing"
            style={{ backgroundColor: "#fff", justifyContent: "center" }}
          >
            <div style={{ paddingLeft: "0" }}>
              <p className="uk-body">
                {/* @ts-ignore */}
                {article.attributes.content.map((content) => (
                  <>
                    {content.type === "image" ? (
                      <div style={{ paddingBottom: "1rem" }}>
                        <Card.Img
                          src={getImageUrl(content.image?.url)}
                          alt=""
                          className="card-image-size"
                        />
                      </div>
                    ) : null}
                    {content.children.map((children: any) => (
                      <Markdown>{children.text}</Markdown>
                    ))}
                  </>
                ))}
              </p>
              {check === 1 ? (
                <button
                  className="button"
                  type="button"
                  onClick={() => pdfGenerate(article.attributes)}
                >
                  {t("download")}
                </button>
              ) : (
                ""
              )}
            </div>
          </Row>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default ArticleComponent;
