import React from 'react';
import flagForFlagUnitedKingdom from '@iconify-icons/twemoji/flag-for-flag-united-kingdom';
import flagForRomania from '@iconify-icons/emojione-v1/flag-for-romania';
import { Icon } from '@iconify/react';
import { useI18n } from '../../hooks/useI18n';

const LanguageDropdown = () => {
  const { language, changeLanguage, LANGUAGES } = useI18n();

  const handleLanguageChange = (newLanguage) => {
    changeLanguage(newLanguage);
  };

  return (
    <div className="dropdown">
      <button
        type="button"
        onClick={() =>
          handleLanguageChange(
            language === LANGUAGES.EN ? LANGUAGES.RO : LANGUAGES.EN
          )
        }
        className="buttonlan"
      >
        <Icon
          icon={
            language === LANGUAGES.EN
              ? flagForRomania
              : flagForFlagUnitedKingdom
          }
          width="30px"
          height="30px"
        />
      </button>
    </div>
  );
};

export default LanguageDropdown;
