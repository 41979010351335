import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { useQuery } from '@tanstack/react-query';
import { ArticlesComponent } from '../../../components/Articles';
import { api } from '../../../api/api';
import { Wrapper, Article } from '../../../types';
import DataDisplay from '../../../components/DataDisplay';
import { useI18n } from '../../../hooks/useI18n';

const INTERNATIONAL_NEWS_CATEGORY_ID = 2;
const LOCAL_NEWS_CATEGORY_ID = 7;
const RO_INTERNATIONAL_NEWS_CATEGORY_ID = 1;
const RO_LOCAL_NEWS_CATEGORY_ID = 8;
const ARCHIVED_NEWS_CATEGORY_ID = 999;

const Stire = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = React.useState(LOCAL_NEWS_CATEGORY_ID);
  const i18n = useI18n();

  const { data: responseData, isLoading, isError } = useQuery({
    queryKey: [{ name: 'stire', id: activeTab, isArchived: false, locale: i18n.language }],
    queryFn: ({ queryKey }) => {
      if (queryKey[0].id === ARCHIVED_NEWS_CATEGORY_ID) {
        return api.get<Wrapper<Article>>(`/articles?populate[0]=category&locale=${queryKey[0].locale}&filters[isArchived][$eq]=true&sort=publishedDate:desc`);
      }
      if (queryKey[0].locale === 'ro') {
        if (queryKey[0].id === INTERNATIONAL_NEWS_CATEGORY_ID) {
          return api.get<Wrapper<Article>>(`/articles?populate[0]=category&locale=${queryKey[0].locale}&filters[category][id][$eq]=${RO_INTERNATIONAL_NEWS_CATEGORY_ID}&filters[isArchived][$eq]=false&sort=publishedDate:desc`);
        } else {
          return api.get<Wrapper<Article>>(`/articles?populate[0]=category&locale=${queryKey[0].locale}&filters[category][id][$eq]=${RO_LOCAL_NEWS_CATEGORY_ID}&filters[isArchived][$eq]=false&sort=publishedDate:desc`);
        }
      }
      return api.get<Wrapper<Article>>(`/articles?populate[0]=category&locale=${queryKey[0].locale}&filters[category][id][$eq]=${queryKey[0].id}&filters[isArchived][$eq]=false&sort=publishedDate:desc`);
    },
  });

  const handleTabClick = (tab: number) => {
    setActiveTab(tab);
  };

  const getTranslationKey = (tab: number) => {
    switch (tab) {
      case LOCAL_NEWS_CATEGORY_ID:
        return 'stiri_locale';
      case INTERNATIONAL_NEWS_CATEGORY_ID:
        return 'stiri_internationale';
      case ARCHIVED_NEWS_CATEGORY_ID:
        return 'stiri_arhivate'
      default:
        return '';
    }
  };

  return (
    <div className="inside-page">
      <div className="tab-navigation">
        <div className="tab-header">
          {[LOCAL_NEWS_CATEGORY_ID, INTERNATIONAL_NEWS_CATEGORY_ID, ARCHIVED_NEWS_CATEGORY_ID].map((tab) => (
            <button
              key={tab}
              className={`tab-button ${
                activeTab === tab ? 'active' : ''
              }`}
              onClick={() => handleTabClick(tab)}
            >
              {t(getTranslationKey(tab))}
            </button>
          ))}
        </div>
      </div>
      <Row style={{ minHeight: '30dvh' }}>
        <Col md>
          <div className="uk-section">
            <DataDisplay
              responseData={responseData}
              isLoading={isLoading}
              isError={isError}
              RenderComponent={ArticlesComponent} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Stire;
