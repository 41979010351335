import React from 'react';
import { useTranslation } from 'react-i18next';
import Footer from '../../../components/Footer';
import Navigation from '../../../components/Nav/navbar';
import CarouselDespreNoi from './CarouselDespreNoi';

export default function PoliticaDeConfidentialitate() {
  const { t } = useTranslation();
  return (
    <div>
      <Navigation />
      <CarouselDespreNoi />
      <div className="inside-page">
        <h1 style={{ marginBottom: '1rem' }}>{t('politica')} </h1>

        <h2>{t('politica_1')}</h2>

        <article>
          <p>{t('politica_2')}</p>

          <p>{t('politica_3')}</p>
        </article>

        <h2>{t('politica_4')}</h2>

        <article>
          <p>{t('politica_5')}</p>

          <p>{t('politica_6')}</p>
        </article>

        <h2>{t('politica_7')}</h2>

        <article>
          <p>{t('politica_8')}</p>

          <p>{t('politica_9')}</p>
        </article>

        <h2>{t('politica_10')}</h2>

        <article>
          <p>{t('politica_11')}</p>

          <p>{t('politica_12')}</p>
        </article>

        <h2>{t('politica_13')}</h2>

        <article>
          <p>{t('politica_14')}</p>

          <p>{t('politica_15')}</p>
        </article>

        <h2>{t('politica_16')}</h2>

        <article>
          <p>{t('politica_17')}</p>
          <ul style={{ listStyleType: 'none' }}>
            <li>{t('politica_18')}</li>

            <li>{t('politica_19')}</li>

            <li>{t('politica_20')}</li>

            <li>{t('politica_21')}</li>

            <li>{t('politica_22')}</li>

            <li>{t('politica_23')}</li>

            <li>{t('politica_24')}</li>

            <li>{t('politica_25')}</li>

            <li>{t('politica_26')}</li>
          </ul>
        </article>

        <h3>{t('politica_18')}</h3>

        <article>
          <p>
            {t('politica_27')}
            <a
              href="https://support.google.com/analytics/answer/6004245"
              target="_blank"
              rel="noreferrer"
            >
              {' '}
              Google Analytics
            </a>
            .
          </p>
          <p>{t('politica_28')}</p>
          <p>{t('politica_29')}</p>
        </article>

        <h3>{t('politica_19')}</h3>

        <article>
          <p>{t('politica_30')}</p>

          <p>{t('politica_31')}</p>

          <p>{t('politica_32')}</p>

          <p>{t('politica_33')}</p>

          <p>{t('politica_34')}</p>
        </article>

        <h3>{t('politica_20')}</h3>

        <article>
          <p>{t('politica_35')}</p>

          <p>{t('politica_36')}</p>
        </article>

        <h3>{t('politica_21')}</h3>

        <article>
          <p>{t('politica_37')}</p>
        </article>

        <h3>{t('politica_22')}</h3>

        <article>
          <p>{t('politica_38')}</p>

          <p>{t('politica_39')}</p>

          <p>{t('politica_40')}</p>

          <p>{t('politica_41')}</p>

          <p>{t('politica_42')}</p>

          <p>{t('politica_43')}</p>

          <p>
            {t('politica_44')}
            <a
              href="mailto:info@jbaconsulting.ro"
              target="_blank"
              rel="noreferrer"
            >
              {' '}
              info@jbaconsulting.ro.
            </a>
          </p>
        </article>

        <h3>{t('politica_23')}</h3>

        <article>
          <p>{t('politica_45')}</p>

          <p>{t('politica_46')}</p>

          <p>{t('politica_47')}</p>

          <p>{t('politica_48')}</p>

          <p>{t('politica_49')}</p>

          <p>
            {t('politica_50')}{' '}
            <a
              href="mailto:info@jbaconsulting.ro"
              target="_blank"
              rel="noreferrer"
            >
              {' '}
              info@jbaconsulting.ro
            </a>
            .
          </p>
        </article>

        <h3>{t('politica_24')}</h3>

        <article>
          <p>{t('politica_51')}</p>

          <p>{t('politica_52')}</p>

          <p>{t('politica_53')}</p>

          <p>{t('politica_54')}</p>

          <p>{t('politica_55')}</p>

          <p>{t('politica_56')}</p>

          <p>{t('politica_57')}</p>

          <p>{t('politica_58')}</p>

          <p>{t('politica_59')}</p>

          <p>{t('politica_60')}</p>

          <p>
            {t('politica_61')}{' '}
            <a
              href="mailto:info@jbaconsulting.ro"
              target="_blank"
              rel="noreferrer"
            >
              info@jbaconsulting.ro
            </a>
            .
          </p>
        </article>

        <h3>{t('politica_25')}</h3>

        <article>
          <p>{t('politica_62')}</p>

          <p>
            {t('politica_63')}{' '}
            <a
              href="mailto:data.privacy@jbagroup.co.uk"
              target="_blank"
              rel="noreferrer"
            >
              {' '}
              data.privacy@jbagroup.co.uk
            </a>{' '}
            {t('politica_64')}
          </p>
        </article>

        <h3>{t('politica_26')}</h3>

        <article>
          <p>{t('politica_65')}</p>

          <p>{t('politica_66')}</p>
        </article>

        <h2>{t('politica_67')}</h2>

        <article>
          <p>{t('politica_68')}</p>

          <p>{t('politica_69')}</p>

          <p>{t('politica_70')}</p>

          <p>{t('politica_71')}</p>
        </article>

        <h2>{t('politica_72')}</h2>

        <article>
          <p>{t('politica_73')}</p>

          <p>{t('politica_74')}</p>

          <ol>
            <li>{t('politica_75')}</li>
            <li>{t('politica_76')}</li>
            <li>{t('politica_77')}</li>
            <li>{t('politica_78')}</li>
          </ol>
          <p>{t('politica_79')}</p>

          <p>{t('politica_80')}</p>

          <p>{t('politica_81')}</p>

          <p>{t('politica_82')}</p>

          <p>
            {t('politica_83')}{' '}
            <a
              href="https://www.dataprotection.ro"
              target="_blank"
              rel="noreferrer"
            >
              Data Protection
            </a>
            {t('politica_84')}
          </p>
        </article>

        <h2>{t('politica_85')}</h2>

        <article>
          <p>{t('politica_86')}</p>
        </article>

        <h2>{t('politica_88')}</h2>

        <article>
          <p>{t('politica_89')}</p>
        </article>

        <h2>
          <p>{t('politica_90')}</p>{' '}
        </h2>

        <article>
          <p>
            <strong>{t('politica_91')}</strong>, JBA Group, 1 Broughton Park,
            Old Lane North, Broughton, Skipton BD23 3FD, UK. E-mail
            <a
              href="mailto:data.privacy@jbagroup.co.uk"
              target="_blank"
              rel="noreferrer"
            >
              data.privacy@jbagroup.co.uk
            </a>
            .
          </p>
        </article>

        <h2>{t('politica_92')}</h2>

        <article>
          <p>
            {t('politica_93')}{' '}
            <a
              href="https://www.dataprotection.ro/?page=Plangeri_pagina_principala"
              target="_blank"
              rel="noreferrer"
            >
              Data Protection
            </a>{' '}
          </p>
          <p>
            {t('politica_94')}{' '}
            <a href="tel:0761104800" target="_blank" rel="noreferrer">
              0761 104 800
            </a>{' '}
          </p>
          <p>{t('politica_95')}</p>
        </article>

        <h2>Cookies</h2>

        <article>
          <p>{t('politica_96')}</p>
        </article>

        <h2>{t('politica_97')}</h2>

        <article>
          <p>
            {t('politica_98')}{' '}
            <a
              href="https://www.allaboutcookies.org"
              target="_blank"
              rel="noreferrer"
            >
              {' '}
              All About Cookies
            </a>
            .
          </p>
          <p>{t('politica_99')}</p>

          <ul>
            <li>Google Chrome</li>

            <li>Microsoft Edge</li>

            <li>Mozilla Firefox</li>

            <li>Microsoft Internet Explorer</li>

            <li>Opera</li>

            <li>Apple Safari</li>
          </ul>

          <p>{t('politica_100')}</p>

          <p>
            {t('politica_101')}{' '}
            <a
              href="https://tools.google.com/dlpage/gaoptout"
              target="_blank"
              rel="noreferrer"
            >
              Opt Out
            </a>
          </p>
        </article>
      </div>
      <Footer />
    </div>
  );
}
