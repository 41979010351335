import { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import DotLoader from 'react-spinners/DotLoader';
import App from './containers/App/index';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createRoot } from 'react-dom/client';
import { Partytown } from '@builder.io/partytown/react';

import 'bootstrap/dist/css/bootstrap.css';
import './stylesheets/main.scss';
import './i18n';

const queryClient = new QueryClient();

const root = createRoot(document.getElementById('root'));

const Loading = () => <div style={{
  height: '100vh',
  width: '100vw',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}}><DotLoader color="#70C9D5" /></div>

root.render(
  <>
    <Partytown debug={true} forward={['dataLayer.push']} />
    <script
      type="text/partytown"
      async
      src="https://www.googletagmanager.com/gtag/js?id=G-XHD2687G0D"
    />
    <script
      type="text/partytown"
      dangerouslySetInnerHTML={{
        __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-XHD2687G0D');
        `,
      }}
    />
    <Router>
      <QueryClientProvider client={queryClient}>
        <Suspense fallback={<Loading />}>
          <App />
        </Suspense>
      </QueryClientProvider>
    </Router>,
  </>
);
