import React from 'react';

interface DataDisplayProps<T> {
  responseData: any;
  isLoading: boolean;
  isError: boolean;
  RenderComponent: React.ComponentType<{ data: T[] | any }>;
}

function DataDisplay<T>({ responseData, isLoading, isError, RenderComponent }: DataDisplayProps<T>) {
  return (
    <>
      {responseData ? (
        <RenderComponent data={responseData.data.data} />
      ) : isLoading ? (
        <div>Loading...</div>
      ) : isError ? (
        <div>Error fetching data</div>
      ) : null}
    </>
  );
}

export default DataDisplay;
