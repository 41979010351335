/* eslint-disable react/no-danger */
import React from 'react';
import { useTranslation } from 'react-i18next';
import Footer from '../../../components/Footer';
import Navigation from '../../../components/Nav/navbar';

export default function SuntemJba() {
  const { t } = useTranslation();
  return (
    <div>
      <Navigation />
      <div className="herovalori" />
      <div className="inside-page">
        <div dangerouslySetInnerHTML={{ __html: t('valori') }} />
        <p>
          <br />
          {t('valori1')}
          <br />
          {t('valori2')}
          <br />
          {t('valori3')}
          <br />
          {t('valori4')}
          <br />
          {t('valori5')}
          <br />
          {t('valori6')}
        </p>
      </div>
      <Footer />
    </div>
  );
}
