import React from 'react';
// Components
import { Card } from 'react-bootstrap';
import Navbar from '../../../components/Nav/navbar';
import Footer from '../../../components/Footer';
// Images
import KeyFacts from '../../../assets/images/grupul-jba/key-facts.png';

export default function InformatiiCheie() {
  return (
    <div>
      <Navbar />
      <div
        style={{
          display: 'grid',
          placeItems: 'center',
          borderBottom: 'solid 0.5rem #70c9d5',
          height: '60vh',
          marginBottom: '50px',
        }}
      >
        <Card.Img
          src={KeyFacts}
          alt="informatii cheie"
          style={{
            border: 'solid .3px gray',
            width: '500px',
          }}
        />
      </div>
      <Footer />
    </div>
  );
}
