import React from 'react';
import Footer from '../../../components/Footer';
import Navigation from '../../../components/Nav/navbar';

import Stire from './Stire';

const Category = () => (
  <div>
    <Navigation />
    <div className="herostiri" />
    <div className="inside-domains-page">
      <Stire />
    </div>
    <Footer />
  </div>
);

export default Category;
