import axios from 'axios';

export let API_URL = 'https://jbaconsulting.ro/api';
// if (process.env.NODE_ENV !== 'production') {
//   API_URL = 'http://localhost:1337';
// }

export const api = axios.create({
  baseURL: `${API_URL}/api`,
  headers: {
    'Authorization': 'Bearer 78c8ff44e28ef55469662a7ad1ff555831379c77a1bb3be4c0799983d375b7514927fe4c520a309a3c775bbdb0c0528f3ee5a4f8fc5ed217a50044646cfd8815eaab1d7eb25e8ed63ebd81d59b8ea4e91bf443b86dd7454407d831f877a25b1fbf4c42944228986f25cad631b81e7d022c5dee3d20be5e2fe0bdcb18635b365e',
  }
});
