import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Footer from '../../../components/Footer';
import Navigation from '../../../components/Nav/navbar';
import { useI18n } from '../../../hooks/useI18n';
// Images
import Uctb from '../../../assets/vectors/logo-utcb-ro.svg';
import UPT from '../../../assets/images/parteneri/upt.png';
import Wwf from '../../../assets/vectors/wwf_logo_full.svg';
import Fg from '../../../assets/images/parteneri/geografie.png';
import Fgb from '../../../assets/images/parteneri/geografie-babes.png';
import PolitehnicaEnergetica from '../../../assets/images/parteneri/energetica.png';
import IGAR from '../../../assets/images/parteneri/sigla_IGAR.jpg';

export default function Parteneri() {
  const { t } = useTranslation();
  const { language, LANGUAGES } = useI18n();
  const companyName = <strong>JBA Consult Europe</strong>;
  const partnershipText = language === LANGUAGES.EN
    ? <>Partnership agreements of {companyName} with public / private companies / NGOs</>
    : <>Acorduri de parteneriat ale {companyName} cu companii publice / private / ONG-uri</>;

  return (
    <div>
      <Navigation />
      <div className="inside-page" style={{ minHeight: '80vh' }}>
        <h1 className="title-asociatii">{partnershipText}</h1>
        <div>
          <Row className="row">
            <Col md className="col-md-2">
              <img src={Uctb} alt="" width="110rem" height="110rem" />
            </Col>
            <Col style={{ paddingTop: '15px' }}>
              <p>
                <strong>{t('utcb_value')}</strong>
                {t('utcb')}
              </p>
            </Col>
          </Row>
          <Row className="row">
            <Col md className="col-md-2">
              <img
                src={Wwf}
                alt=""
                width="70rem"
                height="70rem"
                style={{ marginLeft: '1rem', marginTop: '5px' }}
              />
            </Col>
            <Col>
              <p>
                <strong>{t('wwf_value')}</strong>
                {t('wwf')}
              </p>
            </Col>
          </Row>
          <Row className="row">
            <Col md className="col-md-2">
              <img
                src={PolitehnicaEnergetica}
                alt=""
                width="140px"
                height="70px"
                style={{ marginLeft: '-15px' }}
              />
            </Col>
            <Col>
              <p>
                <strong>{t('fe_value')}</strong>
                {t('energetica')}
              </p>
            </Col>
          </Row>
          <Row className="row">
            <Col md className="col-md-2">
              <img src={Fg} alt="" width="120" height="100" />
            </Col>
            <Col>
              <p>
                <strong>{t('fg_value')}</strong>
                {t('geografie')}
              </p>
            </Col>
          </Row>
          <Row className="row">
            <Col md className="col-md-2">
              <img src={UPT} alt="" width="120" height="90" />
            </Col>
            <Col>
              <p>
                <strong>{t('upt_value')}</strong>
                {t('upt')}
              </p>
            </Col>
          </Row>
          {
            language === LANGUAGES.EN
              ? <>
                <Row className="row">
                  <Col md className="col-md-2">
                    <img src={IGAR} alt="" width="120" height="120" />
                  </Col>
                  <Col>
                    <p>
                      <strong>The Institute of Geography of the Romanian Academy (IGAR) - </strong>
                      established in 1944, is the main institution of fundamental and applied geographical research in Romania. It carries out interdisciplinary research in the domains of Physical Geography, Human Geography and Environmental Geography.
                    </p>
                  </Col>
                </Row>
                <Row className="row">
                  <Col md className="col-md-2">
                    <img src={Fgb} alt="" width="120" height="120" />
                  </Col>
                  <Col>
                    <p>
                      <strong>The Faculty of Geography, Babeș-Bolyai University - </strong>
                      is among the most dynamic entities of Cluj University.  Of great relevance are bachelor's, master's programs and postgraduate programs (e.g.: Hidrology and Meteorology, Evaluation and Management of Geographical Hazards and Risks, Spatial modeling and analysis in ArcGIS and HecRAS environments, Coupled hydrological and hydraulic modeling for flood risk assessment on watercourses).
                    </p>
                  </Col>
                </Row>
              </>
              : <>
                <Row className="row">
                  <Col md className="col-md-2">
                    <img src={IGAR} alt="" width="120" height="120" />
                  </Col>
                  <Col>
                    <p>
                      <strong>Institutul de Geografie al Academiei Române (IGAR) - </strong>
                      înființat în anul 1944, este principala instituție care desfășoară cercetări geografice fundamentale și aplicate în România. Cercetările au un caracter interdisciplinar și cuprind domeniile geografie fizică, geografie umană și geografia mediului.
                    </p>
                  </Col>
                </Row>
                <Row className="row">
                  <Col md className="col-md-2">
                    <img src={Fgb} alt="" width="120" height="120" />
                  </Col>
                  <Col>
                    <p>
                      <strong>Facultatea de Geografie, Universitatea Babeș-Bolyai - </strong>
                      ste una dintre cele mai dinamice entități ale Universității din Cluj.  De mare interes sunt programele de licență, masterat și cele postuniversitare (ex. de specializări: Hidrologie și Meteorologie, Evaluarea și Gestiunea Hazardurilor și Riscurilor Geografice, Modelare şi analiză spaţială ArcGIS şi HecRAS, Modelarea cuplată hidrologică și hidraulică pentru evaluarea hazardului la inundații pe cursurile de apă).
                    </p>
                  </Col>
                </Row>
              </>
          }
        </div>
      </div>
      <Footer />
    </div>
  );
}
