import React from 'react';
import { useTranslation } from 'react-i18next';
import Footer from '../../../components/Footer';
import Navigation from '../../../components/Nav/navbar';
import CarouselDespreNoi from './CarouselDespreNoi';

export default function TermeniSiConditii() {
  const { t } = useTranslation();
  return (
    <div>
      <Navigation />
      <CarouselDespreNoi />
      <div className="inside-page">
        <h1 style={{ marginBottom: '1rem' }}>{t('termeni')}</h1>
        <article style={{ marginBottom: '.5rem' }}>{t('termeni_1')}</article>
        <article style={{ marginBottom: '.5rem' }}>{t('termeni_2')}</article>

        <article style={{ marginBottom: '.5rem' }}>{t('termeni_3')}</article>

        <article style={{ marginBottom: '.5rem' }}>{t('termeni_4')}</article>

        <article style={{ marginBottom: '.5rem' }}>{t('termeni_5')}</article>

        <article style={{ marginBottom: '.5rem' }}>{t('termeni_6')}</article>

        <article style={{ marginBottom: '.5rem' }}>{t('termeni_7')}</article>

        <article style={{ marginBottom: '.5rem' }}>{t('termeni_8')}</article>

        <article style={{ marginBottom: '.5rem' }}>{t('termeni_9')}</article>

        <article style={{ marginBottom: '.5rem' }}>{t('termeni_10')}</article>

        <article style={{ marginBottom: '.5rem' }}>{t('termeni_11')}</article>
      </div>
      <Footer />
    </div>
  );
}
