import React from 'react';
import {
  GoogleMap,
  Marker,
  TrafficLayer,
  useJsApiLoader,
} from '@react-google-maps/api';

import image from '../../assets/vectors/logo1.svg';

import './map.css';

const containerStyle = {
  width: '40rem',
  height: '40rem',
};

const MAP_CENTER = { lat: 44.46759646098969, lng: 26.068995555951545 };

function Map() {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyAp2zLRrg2erL0qBGioBUEmxqt6AVhNgZw',
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(MAP_CENTER);
    map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      defaultZoom={15}
      center={MAP_CENTER}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      <Marker position={MAP_CENTER} icon={image} />
      <TrafficLayer autoUpdate />
    </GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(Map);
