import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Navigation from '../../components/Nav/navbar';
import Footer from '../../components/Footer';
import { useI18n } from '../../hooks/useI18n';

export default function AcreditariCertificari() {
  const { t } = useTranslation();
  const { language, LANGUAGES } = useI18n();

  return (
    <div>
      <Navigation />
      <Container
        style={{ textAlign: 'left', padding: '0rem 4rem', minHeight: '70dvh' }}
      >
        <Row>
          <Col md>
            <strong>JBA Consult Europe SRL </strong>
            {
              language === LANGUAGES.EN
                ? <>is <strong>certified </strong>
                  (Certificate no. 153/05.07.2023 and Certificate no. 377/10.01.2024) according to Order 1287 from 2021 regarding the organization of the attestation activity of specialized public or private institutions
                  <strong> in the elaboration of the documentation supporting the request for the water management permits </strong>
                  and <strong>water management licenses, </strong> in the following areas:
                  <ul style={{ marginTop: '.5rem' }}>
                    <li>water management studies (C);</li>
                    <br />
                    <li>documentations for obtaining the water management permits / licenses
                      (D1, D7);</li>
                    <br />
                    <li>studies for impact assessment on water body (E).</li>
                  </ul>
                </>
                : <>este <strong>atestată </strong>
                   (Atestat nr. 153/05.07.2023 și Atestat nr. 377/10.01.2024) conform Ordinului 1287 din 2021 privind organizarea activității de atestare a instituțiilor publice sau private specializate
                  <strong> în elaborarea documentațiilor pentru fundamentarea solicitării avizului de gospodărire a apelor </strong>
                  şi a <strong>autorizației de gospodărire a apelor,</strong> în următoarele domenii:
                  <ul style={{ marginTop: '.5rem' }}>
                    <li>întocmirea studiilor de gospodărire a apelor (C);</li>
                    <br />
                    <li>elaborarea documentațiilor pentru obținerea avizului/autorizației de
                      gospodărire a apelor (D1, D7);</li>
                    <br />
                    <li>elaborarea studiilor de evaluare a impactului asupra corpurilor de apă
                      (SEICA) (E).</li>
                  </ul>
                </>
            }
          </Col>
          <Col md>
            <p style={{ marginBottom: '.8rem' }}>
              <strong>JBA Consult Europe SRL </strong>
              {
                language === LANGUAGES.EN
                  ? 'is accredited to ISO 9001:2015 - Quality Assurance standards (SGS Certificate GB21/968877.08).'
                  : 'este acreditată la standardele ISO 9001:2015 - Asigurarea Calității (Certificat SGS GB21/968877.08).'
              }
            </p>
            <p>
              <strong>  
                {
                  language === LANGUAGES.EN
                  ? 'The JBA Group'
                  : 'Grupul JBA'
                }
              </strong>
              {
                language === LANGUAGES.EN 
                ? " is accredited to ISO 14001 (Environmental Management) standards and OHSAS 18001 (Health and Safety) standards."
                : " este acreditat la standardele ISO 14001 (Managementul Mediului) și Standardele OHAS 18001 (Sănătate și Securitate)."
              }
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}
