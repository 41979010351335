/* eslint-disable */
import styled from 'styled-components';

// eslint-disable-next-line
const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: white;
  height: 100%;
  padding: 0rem;
  padding-bottom: 100rem;

  position: fixed;
  top: 37%;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
  z-index: 10000;

  @media (max-width: 700px) {
    width: 100%;
  }

  a {
    text-transform: uppercase;
    padding: 2rem 2rem;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: #70c9d5;
    text-decoration: none;
    transition: color 0.3s linear;
    background-color: white;

    @media (max-width: ${({ theme }) => theme.mobile}) {
      font-size: 1rem;
      text-align: left;
    }

    &:hover {
      color: ${({ theme }) => theme.primaryHover};
    }
  }
`;
export default StyledMenu;
/* eslint-disable */
