import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Footer from '../../../components/Footer';
import Navigation from '../../../components/Nav/navbar';
// Images
import Riba from '../../../assets/images/parteneri/riba.jpeg';
import Iahs from '../../../assets/images/parteneri/iahs.gif';
import Aric from '../../../assets/images/parteneri/aric.jpg';

export default function Parteneri() {
  const { t } = useTranslation();
  return (
    <div>
      <Navigation />
      <div className="inside-page" style={{ minHeight: '70vh' }}>
        <h1 className="uk-card-title">{t('parteneri_membri')}</h1>

        <Row className="row">
          <Col md className="col-md-2">
            <a href="http://aric.org.ro/" target="_blank" rel="noreferrer">
              <img src={Aric} alt="aric" width="100" height="100" />
            </a>
          </Col>
          <Col md>
            <strong>{t('parteneri_aric')}</strong>
            {t('parteneri_aric2')}
          </Col>
        </Row>
        <Row className="row">
          <Col md className="col-md-2">
            <a href="https://iahs.info/" target="_blank" rel="noreferrer">
              <img
                src={Iahs}
                alt="iahs"
                width="87"
                height="87"
                style={{ position: 'relative', left: '.5rem' }}
              />
            </a>
          </Col>
          <Col md>
            <strong>{t('parteneri_iahs')}</strong>
            {t('parteneri_iahs2')}
          </Col>
        </Row>
        <Row className="row">
          <Col md className="col-md-2">
            <a href="https://riba.org.ro/" target="_blank" rel="noreferrer">
              <img src={Riba} alt="riba" width="100" height="100" />
            </a>
          </Col>
          <Col md>
            <strong>{t('parteneri_riba')}</strong>
            {t('parteneri_riba2')}
          </Col>
        </Row>
      </div>
      <Footer />
    </div>
  );
}
