import React from 'react';
import { bool } from 'prop-types';
import { Link } from 'react-router-dom';
import StyledMenu from './Menu.styled';

const Menu = ({ open }) => (
  <StyledMenu open={open}>
    <Link to="/despre-noi">Despre Noi</Link>
    <Link to="/suntem-jba">Suntem JBA</Link>
    <Link to="/domenii-de-consultanta">Domenii Consultanta</Link>
    <Link to="/acreditari-certificari">Acreditari</Link>
    <Link to="/parteneri">Parteneri</Link>
    <Link to="/stiri">Stiri</Link>
    <Link to="/cariera">Cariera</Link>
  </StyledMenu>
);
Menu.propTypes = {
  open: bool.isRequired,
};
export default Menu;
