import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Navigation from '../../../components/Nav/navbar';
import Footer from '../../../components/Footer';
// Vectors
import FloodM from '../../../assets/vectors/flood_risk.svg';
import Training from '../../../assets/vectors/training.svg';
import Legislativ from '../../../assets/vectors/legislativ.svg';
import Consultanta from '../../../assets/vectors/consultanta.svg';

export default function DomeniiConsultanta() {
  const { t } = useTranslation();
  return (
    <div>
      <Navigation />
      <div className="inside-page" style={{ minHeight: '70vh' }}>
        <h3 style={{ textAlign: 'center' }}>{t('consultanta')}</h3>
        <div
          className="flex"
          style={{ textAlign: 'center', fontFamily: 'Avenir' }}
        >
          <Row>
            <Col>
              <Link to="/domenii-de-consultanta/managementul-riscului-inundatii">
                <img src={FloodM} alt="" />
                <br />
                <p style={{ fontSize: '22px' }}>{t('nav_managementul')}</p>
              </Link>
            </Col>
            <Col>
              <Link to="/domenii-de-consultanta/consultanta-gestionare">
                <img src={Consultanta} alt="" />
                <br />
                <p style={{ fontSize: '22px' }}>{t('nav_consultanta')}</p>
              </Link>
            </Col>
            <Col>
              <Link to="/domenii-de-consultanta/consultanta-legislativa">
                <img src={Legislativ} alt="" />
                <br />
                <p style={{ fontSize: '22px' }}>{t('nav_cons_leg')}</p>
              </Link>
            </Col>
            <Col>
              <Link to="/domenii-de-consultanta/training-dedicat">
                <img src={Training} alt="" />
                <br />
                <p style={{ fontSize: '22px' }}>{t('nav_training')}</p>
              </Link>
            </Col>
          </Row>
        </div>
      </div>

      <Footer />
    </div>
  );
}
