import { React } from 'react';

import { Col, Row } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import Footer from '../../components/Footer';
import Navigation from '../../components/Nav/navbar';
import CookieConsent from '../../components/CookieConsent';

// Vectors
import FloodM from '../../assets/vectors/flood_risk.svg';
import Training from '../../assets/vectors/training.svg';
import Legislativ from '../../assets/vectors/legislativ.svg';
import Consultanta from '../../assets/vectors/consultanta.svg';

// Carousel
import Carousel from './Carousel';

const Home = () => {
  const { t } = useTranslation();

  return (
    <div className="homepage">
      <Navigation />
      <div className="containerintro">
        <Carousel />
        <div className="stack-top container-fluid">
          <h1>{t('homepage_intro')}</h1>
          <Link to="/despre-noi/contact">
            <button className="button" type="button">
              {t('contact_us')}
            </button>
          </Link>
        </div>
      </div>
      <div className="secondintro">
        <h1>{t('activity')}</h1>
      </div>
      <div className="inside-page">
        <div
          className="flex"
          style={{ textAlign: 'center', fontFamily: 'Avenir' }}
        >
          <Row>
            <Col>
              <Link to="/domenii-de-consultanta/managementul-riscului-inundatii">
                <img src={FloodM} alt="" />
                <br />
                <p style={{ fontSize: '22px' }}>{t('nav_managementul')}</p>
              </Link>
            </Col>
            <Col>
              <Link to="/domenii-de-consultanta/consultanta-gestionare">
                <img src={Consultanta} alt="" />
                <br />
                <p style={{ fontSize: '22px' }}>{t('nav_consultanta')}</p>
              </Link>
            </Col>
            <Col>
              <Link to="/domenii-de-consultanta/consultanta-legislativa">
                <img src={Legislativ} alt="" />
                <br />
                <p style={{ fontSize: '22px' }}>{t('nav_cons_leg')}</p>
              </Link>
            </Col>
            <Col>
              <Link to="/domenii-de-consultanta/training-dedicat">
                <img src={Training} alt="" />
                <br />
                <p style={{ fontSize: '22px' }}>{t('nav_training')}</p>
              </Link>
            </Col>
          </Row>
        </div>
      </div>
      <Footer />
      <CookieConsent />
    </div>
  );
};

export default Home;
