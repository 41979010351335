import React from 'react';
import Card from '../Card';
import { Article, WrapperData } from '../../types';

export const ArticlesComponent = ({ data:articles }:{data: WrapperData<Article>[]}) => {
  return (
    <div>
      {articles.map((article) => (
        <Card key={article.id} article={article} />
      ))}
    </div>
  );
};
