import { API_URL } from "../../api/api";
import { Member } from "../../types";

interface TeamRowProps {
  member: Member;
}

export const TeamRow: React.FC<TeamRowProps> = ({ member }) => {
  return (
    <div className="row-thing" style={{ backgroundColor: "#fff" }}>
      <div style={{ textAlign: "right" }}>
        <img
          src={`${API_URL}${member.profile.data?.attributes.url}`}
          alt=""
          className="echipa-card-img"
        />
      </div>
      <div style={{ width: "60%", paddingLeft: "50px" }}>
        <h2>{member.name}</h2>
        <p className="text-mute">{member.title}</p>
        <p>{member.description}</p>
      </div>
    </div>
  );
};
