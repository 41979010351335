import { React } from 'react';
import { useTranslation } from 'react-i18next';

export default function CookieConsent() {
  const { t } = useTranslation();
  return (
    <div>
    </div>
  );
}
