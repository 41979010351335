import React from 'react';
import { Link } from 'react-router-dom';
import { Article, WrapperData } from '../../types';

const Card = ({ article }:{article: WrapperData<Article>}) => {
  return (
    <Link to={`/article/${article.id}`} className="uk-link-reset">
      <p id="title" className="uk-text-large">
        {article.attributes.title}
      </p>
    </Link>
  )
};

export default Card;
