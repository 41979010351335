import Carousel from 'nuka-carousel';
import React from 'react';
// Carousel Pics
import First from '../../../assets/images/CarouselDespreNoi/carousel1.jpg';
import Forth from '../../../assets/images/CarouselDespreNoi/carousel4.jpg';
import Sixth from '../../../assets/images/CarouselDespreNoi/carousel6.jpg';
import Eight from '../../../assets/images/CarouselDespreNoi/carousel8.jpg';

export default function Carousel1() {
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  if (dimensions.width > 1000) {
    return (
      <Carousel
        style={{
          height: '500px',
          borderBottom: 'solid .5rem rgb(112, 201, 213)',
        }}
        className="carouselintro"
        wrapAround
        autoplay
        autoplayInterval={2000}
        getControlsContainerStyles={(key) => {
          if (key === 'BottomCenter') {
            return {
              backgroundColor: 'transparent',
              color: 'rgb(112, 201, 213)',
            };
          }
          return { display: 'none' };
        }}
        heightMode="first"
        disableAnimation
      >
        <img src={First} alt="" style={{ height: '35rem', width: '100%' }} />
        <img src={Forth} alt="" style={{ height: '35rem', width: '100%' }} />
        <img src={Sixth} alt="" style={{ height: '35rem', width: '100%' }} />
        <img src={Eight} alt="" style={{ height: '35rem', width: '100%' }} />
      </Carousel>
    );
  }
  return (
    <Carousel
      style={{
        height: '500px',
        borderBottom: 'solid .5rem rgb(112, 201, 213)',
      }}
      className="carouselintro"
      wrapAround
      autoplay
      autoplayInterval={10000}
      getControlsContainerStyles={(key) => {
        if (key === 'BottomCenter') {
          return {
            backgroundColor: 'transparent',
            color: 'rgb(112, 201, 213)',
          };
        }
        return { display: 'none' };
      }}
      disableAnimation
      heightMode="current"
    >
      <img src={First} alt="" style={{ height: '35rem', width: '100%' }} />
      <img src={Forth} alt="" style={{ height: '35rem', width: '100%' }} />
      <img src={Sixth} alt="" style={{ height: '35rem', width: '100%' }} />
      <img src={Eight} alt="" style={{ height: '35rem', width: '100%' }} />
    </Carousel>
  );
}
