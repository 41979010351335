import React from 'react';
import { Row, Col, Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { LinkContainer } from 'react-router-bootstrap';
import Logo from '../../assets/images/LogoFooter.svg';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="footer">
      <Row style={{ padding: '0 1rem', margin: '0' }}>
        <Col>
          <img src={Logo} alt="" width="90" height="76" />
        </Col>
        <Col>
          <LinkContainer to="/despre-noi">
            <Nav.Link>{t('nav_despre_noi')}</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/suntem-jba/grupul-jba">
            <Nav.Link>{t('nav_suntem_jba')}</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/despre-noi/contact">
            <Nav.Link>Contact</Nav.Link>
          </LinkContainer>
        </Col>
        <Col>
          <LinkContainer to="/despre-noi/termeni-si-conditii">
            <Nav.Link>{t('nav_ts')}</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/despre-noi/politica-confidentialitate">
            <Nav.Link>{t('nav_pc')}</Nav.Link>
          </LinkContainer>
        </Col>
        <p
          className="copyright"
          style={{ position: 'relative', left: '18 rem' }}
        >
          ©{new Date().getFullYear()} {t('inregistrata')}
        </p>
        <Col />
      </Row>
    </div>
  );
};
export default Footer;
