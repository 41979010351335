import React from 'react';
import { useTranslation } from 'react-i18next';
import { ArticlesComponent } from '../../../components/Articles';
import Footer from '../../../components/Footer';
import Navigation from '../../../components/Nav/navbar';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../../api/api';
import { Wrapper, Article } from '../../../types';
import DataDisplay from '../../../components/DataDisplay';

const Category = () => {
  const { t } = useTranslation();

  const {data: responseData, isLoading, isError} = useQuery({
    queryKey: [{name: 'category'}],
    queryFn: () => {
      return api
        .get<Wrapper<Article>>(`/articles?populate[0]=category&filters[category][id][$eq]=4`);
    }
    });

  return (
    <div>
      <Navigation />
      <div className="herocariera" />
      <div className="inside-page">
        <p>{t('cariera_string1')}</p>
        <p>
          {t('cariera_string2')}
          <a href="mailto:info@jbaconsulting.ro">: info@jbaconsulting.ro. </a>
          <br />
          {t('cariera_string3')}
        </p>
      </div>
      <DataDisplay
        responseData={responseData}
        isLoading={isLoading}
        isError={isError}
        RenderComponent={ArticlesComponent} />
      <Footer />
    </div>
  );
};

export default Category;
