import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Footer from '../../../components/Footer';
import WrappedMap from '../../../components/Map/Map';
import Navigation from '../../../components/Nav/navbar';

export default function Contact() {
  const { t } = useTranslation();
  return (
    <div>
      <Navigation />
      <Row className="inside-page">
        <Col style={{ display: 'flex', backgroundColor: '#fff' }}>
          <Row style={{ padding: '1rem' }}>
            <Col>
              <div style={{ marginRight: 'auto' }}>
                <h1>{t('sediu')}</h1>
                {t('adresa')}
                <br />
                Email:{' '}
                <a href="mailto:info@jbaconsulting.ro">
                  {' '}
                  info@jbaconsulting.ro
                </a>{' '}
                <br />
                Telefon:
                <a href="tel:0040314204598"> +40 314 204 598</a>
                <br />
              </div>
              <br />
              <br />
              <div>
                <h1>{t('date')} </h1>
                {t('forma_juridica')}
                <br />
                {t('nr_inregistrare')}
                <br />
                {t('cui')}
                <br />
                {t('caen')}
              </div>
            </Col>
          </Row>
        </Col>
        <Col
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <WrappedMap />
        </Col>
      </Row>
      <Footer />
    </div>
  );
}
