import { React } from 'react';
import { useTranslation } from 'react-i18next';
import Footer from '../../../components/Footer';
import Navigation from '../../../components/Nav/navbar';
import CarouselDespreNoi from './CarouselDespreNoi';

const DespreNoi = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Navigation />
      <CarouselDespreNoi />
      <div className="inside-page" style={{ paddingTop: '.2rem' }}>
        <p>
          {t('despre_noi_desc_1')}
          <br />
          <br />
          {t('despre_noi_desc_2')}
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default DespreNoi;
