import { useState, useEffect } from 'react';
import i18next from 'i18next';

export function useI18n() {
  const [language, setLanguage] = useState(i18next.language);
  const LANGUAGES = {
    EN: 'en',
    RO: 'ro',
  };

  useEffect(() => {
    const updateLanguage = () => {
      setLanguage(i18next.language);
    };

    i18next.on('languageChanged', updateLanguage);

    return () => {
      i18next.off('languageChanged', updateLanguage);
    };
  }, []);

  const changeLanguage = (lng) => {
    i18next.changeLanguage(lng);
  };

  return { language, changeLanguage, LANGUAGES };
}
