import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'react-bootstrap';
import Footer from '../../../components/Footer';
import Navigation from '../../../components/Nav/navbar';

import Map from '../../../assets/vectors/Map.svg';

export default function SuntemJba() {
  const { t } = useTranslation();

  return (
    <div>
      <Navigation />
      <div className="inside-page">
        <h1>{t('nav_grupul_jba')}</h1>
        <article>{t('grupul1')}</article>
        <article>{t('grupul2')}</article>
        <article>{t('grupul3')}</article>
        <Card.Img
          src={Map}
          alt="Harta Sedii JBA Group"
          className="card-image-size-full"
        />
      </div>
      <Footer />
    </div>
  );
}
