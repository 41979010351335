import Footer from '../../../components/Footer';
import Navigation from '../../../components/Nav/navbar';
import { useI18n } from '../../../hooks/useI18n';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../../api/api';
import { MemberWrapper } from '../../../types';
import { TeamRow } from '../../../components/TeamRow';

const Team = ({department}:{department:string}) => {
  const i18n = useI18n();
  const { data: responseData, isLoading, isError } = useQuery({
      queryKey: [{ id: 'conducere', locale: i18n.language, department }],
        queryFn: async ({ queryKey }) => {
          return await api.get<MemberWrapper>(`/teams?filters[department][$eq]=${queryKey[0].department}&locale[0]=${queryKey[0].locale}&populate=Member%2CMember.profile`);
      },
    },
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error...</div>;
  }

  if (!responseData || !responseData.data || !responseData.data.data || !responseData.data.data.length) {
    return <div>No data</div>;

  }

  console.log(responseData);
  return (
    <div>
      <Navigation />
      <div className="inside-page" style={{ margin: '0 8rem' }}>
        {responseData.data.data.map((members) => members?.attributes.Member.map((member) => <TeamRow member={member} />))}
      </div>
      <Footer />
    </div>
  );
};

export default Team;
