import { Routes, Route } from 'react-router-dom';
import Home from '../Home';
import DespreNoi from '../Pages/DespreNoi/DespreNoi';
import Contact from '../Pages/DespreNoi/Contact';
import TermeniSiConditii from '../Pages/DespreNoi/TermeniSiConditii';
import SuntemJba from '../Pages/SuntemJBA/SuntemJba';
import GrupulJba from '../Pages/SuntemJBA/GrupulJba';
import InformatiiCheie from '../Pages/SuntemJBA/InformatiiCheie';
import ValorileJba from '../Pages/SuntemJBA/ValorileJba';
import StructuraJba from '../Pages/SuntemJBA/StructuraJba';
import DomeniiConsultanta from '../Pages/DomeniiConsultanta/DomeniiConsultanta';
import AcreditariCertificari from '../Pages/AcreditariCertificari';
import Parteneri from '../Pages/Parteneri/Parteneri';
import PoliticaDeConfidentialitate from '../Pages/DespreNoi/PoliticaDeConfidentialitate';
import Stiri from '../Pages/Stiri/Stiri';
import Cariera from '../Pages/Cariera/Cariera';
import Membri from '../Pages/Parteneri/Membri';
import Acorduri from '../Pages/Parteneri/Acorduri';
import ArticleComponent from '../Article';
import ConsultationDomain from '../Pages/DomeniiConsultanta';
import Team from '../Pages/DespreNoi/Team';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home/>} exact />
        <Route path="/article/:id" element={<ArticleComponent/>} exact />
        <Route path="/stiri" element={<Stiri/>} exact />
        <Route path="/cariera" element={<Cariera/>} exact />
        <Route path="/despre-noi" element={<DespreNoi/>} exact />
        <Route path="/despre-noi/conducere" element={<Team department='lead'/>} exact />
        <Route path="/despre-noi/echipa" element={<Team department='staff'/>} exact />
        <Route path="/despre-noi/contact" element={<Contact/>} exact />
        <Route
          path="/despre-noi/termeni-si-conditii"
          element={<TermeniSiConditii/>}
          exact
        />
        <Route
          path="/despre-noi/politica-confidentialitate"
          element={<PoliticaDeConfidentialitate/>}
          exact
        />
        <Route path="/suntem-jba" element={<SuntemJba/>} exact />
        <Route path="/suntem-jba/grupul-jba" element={<GrupulJba/>} exact />
        <Route
          path="/suntem-jba/informatii-cheie"
          element={<InformatiiCheie/>}
          exact
        />
        <Route path="/suntem-jba/valorile-jba" element={<ValorileJba/>} exact />
        <Route
          path="/suntem-jba/structura-jba"
          element={<StructuraJba/>}
          exact
        />
        <Route
          path="/domenii-de-consultanta"
          element={<DomeniiConsultanta/>}
          exact
        />
        <Route path="/domenii-de-consultanta/:category" element={<ConsultationDomain />}/>
        <Route
          path="/acreditari-certificari"
          element={<AcreditariCertificari/>}
          exact
        />
        <Route path="/parteneri" element={<Parteneri/>} exact />
        <Route path="/parteneri/membri" element={<Membri/>} exact />
        <Route
          path="/parteneri/acorduri-de-parteneriat"
          element={<Acorduri/>}
          exact
        />
        <Route path="/cariera" element={<Cariera/>} exact />
      </Routes>
    </div>
  );
}

export default App;
